/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { RemoteTable, env, useZone, Widgets } from "@tol/tol-ui"

function Species() {

  const speciesZone = useZone({
    endpoint: 'treeofsex_species',
    baseUrl: env.TOL_DATA,
    components: [
      { id: 'tos-species-table-v2' }
    ]
  });

  const speciesTable = (
    <RemoteTable
      id="tos-species-table-v2"
      fields={{
        'higher_taxanomic_group': {
          rename: 'Higher Taxanomic Group'
        },
        'order': {
          rename: 'Order'
        },
        'family': {
          rename: 'Family'
        },
        'genus': {
          rename: 'Genus'
        },
        'species': {
          rename: 'Species'
        },
        'species_author': {
          rename: 'Species Author'
        },
        'infraspecific': {},
        'common_name': {},
        'hybrid': {
          rename: 'tester'
        },
        'source_hybrid': {
          rename: 'Source: Hybrid'
        },
        'the_plant_list_v1_1': {
          rename: 'The Plant List v1.1'
        },
        'name_in_the_plant_list_v1_1': {
          rename: 'Name in the Plant List v1.1'
        },
        'sexual_system': {},
        'source_sexual_system': {
          rename: 'Source: Sexual System'
        },
        'selfing': {},
        'source_selfing': {
          rename: 'Source: Selfing'
        },
        'growth_form': {},
        'source_growth_form': {
          rename: 'Source: Growth Form'
        },
        'woodiness': {},
        'woodiness_count': {},
        'source_woodiness': {
          rename: 'Source: Woodiness'
        },
        'life_form': {},
        'source_life_form': {
          rename: 'Source: Life Form'
        },
        'gametophytic_chromosome_num': {
          rename: 'Gametophytic Chromosome Number'
        },
        'gametophytic_chromosome_num_min': {
          rename: 'Gametophytic Chromosome Number Minimum'
        },
        'gametophytic_chromosome_num_mean': {
          rename: 'Gametophytic Chromosome Number Mean'
        },
        'source_gametophytic_chromosome_num': {
          rename: 'Source: Gametophytic Chromosome Number'
        },
        'sporophytic_chromosome_num': {
          rename: 'Sporophytic Chromosome Number'
        },
        'sporophytic_chromosome_num_min': {
          rename: 'Sporophytic Chromosome Number Minimum'
        },
        'sporophytic_chromosome_num_mean': {
          rename: 'Sporophytic Chromosome Number Mean'
        },
        'source_sporophytic_chromosome_num': {
          rename: 'Source: Sporophytic Chromosome Number'
        },
        'karyotype': {},
        'source_karyotype': {
          rename: 'Source: Karyotype'
        },
        'molecular_basis': {},
        'source_molecular_basis': {
          rename: 'Source Molecular Basis'
        },
        'genotypic': {},
        'source_genotypic': {
          rename: 'Source: Genotypic'
        },
        'notes': {},
        'email': {},
        'citation': {}
      }}
      {...speciesZone}
    />
  )

  const title = (
    <h2>Species</h2>
  )

  const components = [
    {
      component: title,
      type: 'full',
    },
    {
      component: speciesTable,
      type: 'xl',
    }
  ]

  return (
    <Widgets components={components} />
  );
};

export default Species;
