/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Widgets, env, RemoteTable, useZone } from '@tol/tol-ui'

function Admin() {

    const zone = useZone({
        endpoint: 'data/user_mailing_lists',
        components: [
            { id: 'admin-table-v1' }
        ]
    });
    const adminTable = (
        <RemoteTable
            id="admin-table-v1"
            {...zone}
        />
    )

    const title = (
        <>
            <h2 style={{marginBottom: "12px"}}>Admin Dashboard</h2>
            <p>Here you can sort the information to view each mailing list and export to CSV.</p>
        </>
    )

    const components = [
        {
            component: title,
            type: 'full',
        },
        {
            component: adminTable,
            type: 'xl',
        }
    ]

    return (
        <div>
            <Widgets components={components} />
        </div>
    )
}

export default Admin;