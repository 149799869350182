/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'rsuite';
import { PopUpMessage, Widgets, httpClient } from '@tol/tol-ui';
import { CountrySelectorDropdown, TextField, TermsAndConditions, CareerSelector, LoadingContent } from '../Components';

const CAREER_STATUS = ["Student", "Post-doc", "ECR Group Leader", "Senior Group Leader", "Non-academic Researcher", "Other"];
const GENERIC_ERROR_MESSAGE = "Sorry an error has occurred, please reload your page and try again.";
const T_AND_CS_ERROR = "Cannot submit, not all terms and conditions have been accepted.";
const MISSING_DATA_ERROR = "Could not save. Please complete all required fields.";
const UNSAVED_CHANGES_MESSAGE = "You have unsaved changes. Are you sure you want to leave?";
const SUCCESS_MESSAGE = "Profile successfully updated.";
const TERMS_AND_CONDITIONS = [
  [
    "I agree to abide by the ",
    "Code of Conduct",
    "https://docs.google.com/document/d/1Mzyio-2NLZyrNH9J5pwOKtM2FuDFPL_QNGU31NrbZfQ/edit#heading=h.j2ubdz5q2r0u"
  ],
  [
    "I agree to the ",
    "Privacy Policy",
    "https://docs.google.com/document/d/11sWx5oxf439W81Jl6pdTz07_hSN8Son_-spham6dRDI/edit#heading=h.cmobflze8by0"
  ],
  [
    "I consent with my personal data being processed as described in the ",
    "Consent Statement",
    "https://docs.google.com/document/d/1sG0rDRIST6JfPaSH1-6J1A9uXn84Jv7heR6qlGhG8T4/edit#heading=h.n307wsht1abf"
  ]];

function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formError, setFormError] = useState<any>({});
  const [modifiedFields, setModifiedFields] = useState<any>({});
  const hasUnsavedChanges = useRef(false);
  const [profile, setProfile] = useState({
    name: '',
    emailAddress: '',
    ORCID: '',
    publicationName: '',
    primaryAffiliation: '',
    affiliationCity: '',
    secondaryAffiliation: '',
    careerStatus: '',
    nationality: '',
  });

  const formRef: any = React.useRef();

  useEffect(() => {
    loadUserData();

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges.current) {
        const message = UNSAVED_CHANGES_MESSAGE;
        e.preventDefault();
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    hasUnsavedChanges.current = Object.keys(modifiedFields).length > 0;
  }, [modifiedFields]);

  const loadUserData = async () => {
    setIsLoading(true);

    try {
      const userId = getUserId();
      const res: any = await httpClient().get('/data/user', {
        params: { filter: JSON.stringify({ and_: { "id": { "eq": { "value": userId } } } }) }
      });

      if (res.status === 200 && res.data.data.length > 0) {
        const data: any = res.data.data[0].attributes;
        setProfileData(data);

        const isNew = !data.name && !data.email;
        setIsNewProfile(isNew);

        setTermsAccepted(data.terms_agreed || false);
      }
    } catch (error) {
      setErrorMessage(GENERIC_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  }

  const setProfileData = (data: any) => {
    setProfile({
      name: data.name || '',
      emailAddress: data.email || '',
      ORCID: data.oidc_id,
      publicationName: data.publication_name || '',
      primaryAffiliation: data.organisation || '',
      affiliationCity: data.affiliation_city || '',
      secondaryAffiliation: data.secondary_affiliation || '',
      careerStatus: data.career_status || '',
      nationality: data.nationality || '',
    });
  }

  const getUserId = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user.id;
  }

  const validateForm = () => {
    if (!formRef.current.check()) {
      setErrorMessage(MISSING_DATA_ERROR);
      return false;
    }
    else {
      return true;
    }
  }

  const handleFieldChange = (name: any, value: any) => {
    setProfile(prev => ({ ...prev, [name]: value }));
    setModifiedFields((prev: any) => ({ ...prev, [name]: value }));
  }

  const handleSubmit = async () => {
    resetAllPopUpMessages();

    if (checkedItems.length !== 3 && !termsAccepted) {
      setErrorMessage(T_AND_CS_ERROR);
    }

    if (validateForm() && (checkedItems.length === 3 || termsAccepted)) {
      try {
        setButtonLoading(true);
        const userId = getUserId();
        const res = await httpClient().post('/data/user:upsert', {
          data: [{
            id: userId,
            type: 'user',
            attributes: {
              name: profile.name,
              email: profile.emailAddress,
              publication_name: profile.publicationName,
              organisation: profile.primaryAffiliation,
              affiliation_city: profile.affiliationCity,
              secondary_affiliation: profile.secondaryAffiliation,
              career_status: profile.careerStatus,
              nationality: profile.nationality,
              terms_agreed: termsAccepted || checkedItems.length === TERMS_AND_CONDITIONS.length,
            }
          }]
        });
        if (res.status === 200) {
          setSuccessMessage(SUCCESS_MESSAGE);
          setTermsAccepted(true);
          setIsNewProfile(false);
          setModifiedFields({});
        }
      } catch (error) {
        setErrorMessage(GENERIC_ERROR_MESSAGE);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  const resetAllPopUpMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  }

  const saveProfileButton = (
    <div className="save-profile-button-wrapper">
      <Button
        appearance="primary"
        disabled={Object.keys(modifiedFields).length === 0}
        loading={buttonLoading}
        type="submit"
        onClick={() => {
          formError && setErrorMessage(MISSING_DATA_ERROR);
        }}
      >
        Save Profile
      </Button>
    </div>
  )

  const profileForm = (
    <div className="profile-form-wrapper">
      <Form
        onCheck={setFormError}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <TextField
          required
          name="name"
          label="Name:"
          placeholder="Name"
          value={profile.name}
          onChange={(value: any) => {
            handleFieldChange("name", value);
          }} />
        <TextField
          required
          name="emailAddress"
          label="Email Address:"
          placeholder="Email Address"
          value={profile.emailAddress}
          type="email"
          onChange={(value: any) => {
            handleFieldChange("emailAddress", value);
          }} />
        <TextField
          centered
          name="ORCID"
          label="ORCID:"
          placeholder="ORCID"
          value={profile.ORCID}
          helpText="This field is automatically populated and cannot be changed."
          readOnly />
        <TextField
          name="publicationName"
          label="Name on Publications (If Different to Given / Family Name):"
          placeholder="Name on Publications (optional)"
          value={profile.publicationName}
          onChange={(value: any) => {
            handleFieldChange("publicationName", value);

          }} />
        <TextField
          name="primaryAffiliation"
          label="Primary Workplace / Institution Affiliation:"
          placeholder="primary workplace/institution"
          value={profile.primaryAffiliation}
          onChange={(value: any) => {
            handleFieldChange("primaryAffiliation", value);
          }} />
        <TextField
          name="affiliationCity"
          label="City / country of primary affiliation:"
          placeholder="Primary city/country"
          value={profile.affiliationCity}
          onChange={(value: any) => {
            handleFieldChange("affiliationCity", value);
          }} />
        <TextField
          name="secondaryAffiliation"
          label="Secondary Workplace / Institutional Affiliation:"
          placeholder="secondary workplace/institution (optional)"
          value={profile.secondaryAffiliation}
          onChange={(value: any) => {
            handleFieldChange("secondaryAffiliation", value);
          }} />
        <CountrySelectorDropdown
          value={profile.nationality}
          setValue={(value: any) => {
            handleFieldChange("nationality", value);
          }} />
        <CareerSelector
          careerStatus={CAREER_STATUS}
          value={profile.careerStatus}
          setValue={(value: any) => {
            handleFieldChange("careerStatus", value);
          }} />
        {(isNewProfile || !termsAccepted) && <TermsAndConditions
          conditions={TERMS_AND_CONDITIONS}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems} />}
        {saveProfileButton}
      </Form>
    </div>
  )

  const tabs = (
    <div className="tabs-wrapper">
      <h3 className="profile-header">{isNewProfile ? "Create Your Profile" : "Update Your Profile"}</h3>
      <div className="tabs-centering">
        {profileForm}
      </div>
    </div>
  )

  const components = isLoading ? [
    {
      component: <LoadingContent wording="Getting profile..." />,
      type: 'full',
    }
  ] : [
    {
      component: tabs,
      type: 'full',
    }
  ]

  return (
    <>
      <PopUpMessage
        type='danger'
        message={errorMessage}
        setMessage={setErrorMessage}
      />
      <PopUpMessage
        type='success'
        message={successMessage}
        setMessage={setSuccessMessage}
      />
      <Widgets
        components={components}
      />
    </>
  );
}

export default Profile;