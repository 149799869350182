/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { useMemo } from 'react';
import { Form } from 'rsuite'; // @ts-ignore
import countryList from 'react-select-country-list'; 
import { SingleSelect } from '@tol/tol-ui';

interface Props {
    value: string;
    setValue: any;
}

function CountrySelectorDropdown(props: Props) {

    const { value, setValue } = props;

    const countryOptions = useMemo(() => countryList().getData(), []);
    const countryItems = countryOptions.map((item: any) => item.label);

    return (
        <Form.Group controlId="formNationalitySelector">
            <Form.ControlLabel>Nationality:</Form.ControlLabel>
            <SingleSelect
                data={countryItems}
                placeholder='Please Select...'
                value={value}
                setValue={setValue}
                block
            />
        </Form.Group>
    )
}

export default CountrySelectorDropdown;