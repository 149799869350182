/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import ReactDOM from 'react-dom';
import { Home, Species, Mailing, Profile, Admin } from './pages';
import reportWebVitals from './reportWebVitals';
import { TolApp, Page } from '@tol/tol-ui';
import './scss/styling.scss';


const species: Page = {
  name: "Species",
  element: <Species />
}

const mailing: Page = {
  name: "Mailing",
  element: <Mailing />,
  auth: true
}

const profile: Page = {
  name: "Profile",
  element: <Profile />,
  auth: true,
}

const admin: Page = {
  name: "Admin",
  element: <Admin />,
  admin: true
}

ReactDOM.render(
  <TolApp
    brand='Tree of Sex'
    homePage={<Home />}
    pages={[
      species,
      mailing,
      profile,
      admin
    ]}
    login={true}
  />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
