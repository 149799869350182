/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { useState, useEffect } from 'react';
import { Form } from 'rsuite';
import { SingleSelect } from '@tol/tol-ui';
import { TextField } from '../Components';

interface Props {
    value: string;
    setValue: Function;
    careerStatus: string[];
}

function CareerSelector({ value, setValue, careerStatus }: Props) {
    const [selectedOption, setSelectedOption] = useState('');
    const [customValue, setCustomValue] = useState('');
    const [showOtherCareerStatus, setShowOtherCareerStatus] = useState(false);

    useEffect(() => {
        if (!value) {
            setSelectedOption('');
            setCustomValue('');
            setShowOtherCareerStatus(false);
        } else if (careerStatus.includes(value)) {
            setSelectedOption(value);
            setShowOtherCareerStatus(false);
            setCustomValue('');
        } else {
            setSelectedOption('Other');
            setCustomValue(value);
            setShowOtherCareerStatus(true);
        }
    }, [value, careerStatus]);

    const handleSelectChange = (newValue: string) => {
        setSelectedOption(newValue);
        if (newValue === 'Other') {
            setShowOtherCareerStatus(true);
            setCustomValue('');
        } else {
            setShowOtherCareerStatus(false);
            setCustomValue('');
            setValue(newValue);
        }
    };

    const handleCustomValueChange = (newValue: string) => {
        setCustomValue(newValue);
        setValue(newValue);
    };

    return (
        <>
            <Form.Group controlId="formCareerStatus">
                <Form.ControlLabel>Career Status:</Form.ControlLabel>
                <SingleSelect
                    data={careerStatus}
                    placeholder='Please Select...'
                    value={selectedOption}
                    setValue={handleSelectChange}
                    block
                />
            </Form.Group>
            {showOtherCareerStatus && (
                <TextField
                    name="customCareerStatus"
                    label="Custom Career Status:"
                    placeholder="Enter your career status"
                    value={customValue}
                    onChange={handleCustomValueChange}
                />
            )}
        </>
    );
}

export default CareerSelector;