/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Form } from 'rsuite';

interface Props {
    name: string;
    label: string;
    accepter?: any;
    helpText?: string;
    placeholder?: string;
    value?: string;
    onChange?: any;
    type?: string;
    readOnly?: boolean;
    required?: boolean;
    centered?: boolean;
}

function TextField(props: Props) {
    const { name, centered, label, accepter, helpText, ...rest } = props;
    const capitalised = label.charAt(0).toUpperCase() + label.slice(1);
    const style = centered && { textAlign: 'center' };
    return (
        <Form.Group controlId={`form${capitalised}`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                style={style}
                name={name}
                accepter={accepter}
                {...rest}
            />
            {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
        </Form.Group>
    )
}

export default TextField;