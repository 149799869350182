/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Form, Checkbox, CheckboxGroup } from 'rsuite';

const T_AND_CS_AGREE = "Please agree to the ToS Terms and Conditions before continuing:";

interface Props {
    conditions: string[][]; // [0] = prefix, [1] = suffix, [2] = link
    checkedItems: string[];
    setCheckedItems: any;
}

function TermsAndConditions(props: Props) {
    const { conditions, checkedItems, setCheckedItems } = props;

    const handleCheckboxChange = (value: string) => {
        if (checkedItems.includes(value)) {
            setCheckedItems(checkedItems.filter((item: string) => item !== value));
        } else {
            setCheckedItems([...checkedItems, value]);
        }
    }

    return (
        <div className="t-and-cs-checkboxes-wrapper">
            <Form.Group controlId="checkbox">
                <Form.ControlLabel>{T_AND_CS_AGREE}</Form.ControlLabel>
                <Form.Control name="checkbox" accepter={CheckboxGroup}>
                    {conditions.map((condition: string[], index: number) => {
                        return (
                            <Checkbox
                                key={index}
                                name={`checkbox${index}`}
                                value={condition[1].replace(/\s+/g, '')}
                                onChange={() => handleCheckboxChange(condition[1])}
                            >
                                {condition[0]}
                                < a href={condition[2]} target="_blank" rel="noreferrer">{condition[1]}</a>.
                            </Checkbox>
                        )
                    })}
                </Form.Control>
            </Form.Group>
        </div>
    )
}

export default TermsAndConditions;