/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import {
  Loader
} from '@tol/tol-ui';

interface Props {
  wording?: string
}

function LoadingContent(props: Props) {
  const {wording} = props;
  return (
    <div className='fixed-full-page'>
    <div className='fixed-centered-loader'>
      <Loader />
    </div>
    <div className='fixed-centered-text'>
      {wording || 'Loading...'}
    </div>
  </div>
  )
}

export default LoadingContent;